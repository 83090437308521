(function(){

    var assets = {

        "js": {
            "page": {

                "main": "gusto/js/app/require-page.js",

                "out": {
                    "combined": "combined/js/page.js",
                    "minified": "combined/js/page.min.js"
                },

                "paths": {
                    "window": "gusto/js/app/gusto.window",
                    "jquery": "gusto/js/app/lib/jquery",
                    "jquery-ui-autocomplete": "gusto/js/app/lib/jquery-ui-autocomplete",
                    "fitvids": "gusto/js/app/lib/jquery.fitvids",
                    "domready": "gusto/js/app/lib/domready",
                    "underscore": "gusto/js/app/lib/underscore",
                    "font-face-observer": "gusto/js/app/lib/font-face-observer.min",
                    "devices": "gusto/js/app/gusto.devices",
                    "smartload": "gusto/js/app/lib/jquery.smartload",
                    "gusto-core": "gusto/js/app/gusto.core",
                    "carousel": "gusto/js/app/gusto.carousel",
                    "base_settings": "gusto/js/app/gusto.settings",
                    "settings": "smooth/js/app/gusto.settings",
                    "localise": "gusto/js/app/gusto.localise",
                    "localise_light": "gusto/js/app/gusto.localise_light",
                    "modal": "gusto/js/app/gusto.modal",
                    "galleries-hd": "gusto/js/app/gusto.galleries.hd",
                    "lazyload": "gusto/js/app/gusto.lazyload",
                    "tabs": "gusto/js/app/gusto.tabs",
                    "tabs-setup": "gusto/js/app/gusto.tabs-setup",
                    "accordion": "gusto/js/app/gusto.accordion",
                    "announcement": "gusto/js/app/gusto.announcement",
                    "app_download": "gusto/js/app/gusto.app_download",
                    "listen": "gusto/js/app/gusto.listen",
                    "cookies": "gusto/js/app/gusto.cookie",
                    "localisation-cookie": "gusto/js/app/gusto.localisation.cookie",
                    "slick": "gusto/js/app/lib/slick.min",
                    "video_placeholder": "gusto/js/app/gusto.video_placeholder",
                    "video_embeds": "gusto/js/app/gusto.video_embeds",
                    "video_facade": "gusto/js/app/gusto.video_facade",
                    "playOverlay": "gusto/js/app/gusto.play-overlay",
                    "navigation": "gusto/js/app/gusto.navigation",
                    "logging": "gusto/js/app/gusto.logging",
                    "tracking": "gusto/js/app/gusto.tracking",
                    "a11y": "gusto/js/app/gusto.a11y",
                },

                "shim": {
                    "tiny-pubsub": ["jquery"],
                    "fitvids": ["jquery"],
                    "smartload": ["jquery"],
                    "transit": ["jquery"],
                    "underscore": {
                        "exports": "_"
                    }
                },

                "insertRequire": [
                    "gusto/js/app/gusto.font_face_observer",
                    "gusto/js/app/gusto.nowplaying",
                    "gusto/js/app/gusto.pagination",
                    "gusto/js/app/gusto.schedule",
                    "gusto/js/app/gusto.dac",
                    "gusto/js/app/gusto.forms",
                    "gusto/js/app/gusto.galleries",
                    "gusto/js/app/gusto.editorial",
                    "gusto/js/app/gusto.timestamps",
                    "gusto/js/app/gusto.now-playing-card",
                    "gusto/js/app/gusto.hashlinks",
                    "gusto/js/app/gusto.traffic_travel",
                    "gusto/js/app/gusto.social-networks",
                    "gusto/js/app/gusto.dating",
                    "gusto/js/app/gusto.trending",
                    "gusto/js/app/gusto.live-article",
                    "navigation",
                    "localisation-cookie",
                    "video_placeholder",
                    "video_embeds",
                    "video_facade",
                    "listen",
                    "accordion",
                    "lazyload",
                    "carousel"
                ]
            },
        },

        "css": {
            "page": {
                "out": {
                    "combined": "combined/css/page.css",
                    "minified": "combined/css/page.min.css"
                },

                "include": [
                    "smooth/css/fonts.css",
                    "gusto/css/normalize.css",
                    "gusto/css/base.css",
                    "gusto/css/grid.css",
                    "gusto/css/BEM_grid.css",
                    "gusto/css/header.css",
                    "gusto/css/now_playing.css",
                    "gusto/css/pagination.css",
                    "gusto/css/player_controls.css",
                    "gusto/css/trending_bar.css",
                    "gusto/css/social_links.css",
                    "gusto/css/navigation.css",
                    "gusto/css/contextual_navigation.css",
                    "gusto/css/editorial.css",
                    "gusto/css/gallery.css",
                    "gusto/css/carousel.css",
                    "gusto/css/on_air.css",
                    "gusto/css/tabs.css",
                    "gusto/css/ads.css",
                    "gusto/css/forms.css",
                    "gusto/css/video_placeholder.css",
                    "gusto/css/video_facade.css",
                    "gusto/css/footer.css",
                    "gusto/css/charts.css",
                    "gusto/css/accordion.css",
                    "gusto/css/category_list.css",
                    "gusto/css/announcement.css",
                    "gusto/css/utility.css",
                    "gusto/css/scribble_live.css",
                    "gusto/css/live_article.css",
                    "gusto/css/social_networks.css",
                    "gusto/css/contact_station.css",
                    "gusto/css/modal.css",
                    "gusto/css/traffic_travel.css",
                    "gusto/css/trending_bar.css",
                    "gusto/css/lib/slick.css",
                    "gusto/css/feature.css",
                    "gusto/css/podcasts.css",
                    "gusto/css/z_index.css",
                    "gusto/css/cadmus.css",
                    "gusto/css/dating.css",
                    "gusto/css/widgets.css",
                    "smooth/css/header.css",
                    "smooth/css/accordion.css",
                    "smooth/css/player_controls.css",
                    "smooth/css/footer.css",
                    "smooth/css/contextual_navigation.css",
                    "smooth/css/navigation.css",
                    "smooth/css/on_air.css",
                    "smooth/css/social_links.css",
                    "smooth/css/editorial.css",
                    "smooth/css/announcement.css",
                    "smooth/css/tabs.css",
                    "smooth/css/video_placeholder.css",
                    "smooth/css/feature.css",
                    "smooth/css/now_playing.css",
                    "smooth/css/dating.css",
                    "smooth/css/trending_bar.css",
                    "smooth/css/forms.css",
                ]

            }

        }

    };

    // Support multiple
    // environments

    if (typeof define === 'function' && define.amd) {

        // RequireJS support
        define('assets',[], assets);

    } else if (typeof exports === 'object') {

        // NodeJS support
        module.exports = assets;

    } else {

        // Global support
        window.assets = assets;

    }


}());

